
import { AiFillInstagram, AiFillYoutube } from 'react-icons/ai'
import { FaFacebook, FaLinkedin } from "react-icons/fa";

import { PiDevicesFill } from 'react-icons/pi'
import './FooterStyle.css';

import { Container, Row, Col } from 'reactstrap';




function Footer() {


  const urlInstagram = "https://www.instagram.com/drumondecrivellari?igsh=M2lmenlhdjQ4djhh";
  //const urlLinkedin = "#"
  //const urlFacebook = "#"
  return (
    <footer className="footer animate__animated animate__fadeIn footer-all">
        <div className='risco-vermelho'></div>
      <div className="container">

        <hr></hr>
         <div className='row space-margin info-footer ' >

          <Container>
            <Row>
              <Col>
              <p className='h5'>Endereço</p>
                        <p>Rua Tanguetá, 243, Centro <br /> Piraúba - Minas Gerais <br /> CEP: 36170-000</p>
              </Col>
              <Col>
              <p className='h5'>Horário de Funcionamento</p>
                        <p>Seg à Sexta: 08:00 às 17:00 <br />Fone: (31) 99122-9441  / 99808-0310 <br /> E-mail: drumondecrivellariadv@gmail.com</p>
              </Col>
              <Col>
              <p className='h5'>Sobre Nós</p>
                        <p className='about-us-footer'>No nosso escritório de advocacia, temos um compromisso inabalável com a justiça e a excelência. Nosso foco é alcançar os melhores resultados para nossos clientes, atuando com dedicação, ética e profissionalismo.</p>
              </Col>

            </Row>
            <p className='text-center'>Rede social</p>
            <div className='d-flex w-100 links-social'>
               <a href={urlInstagram} target="_blank" rel="noreferrer"><li className="in-ico"><AiFillInstagram /></li></a>
            </div>




            <Row>
              <Col>
                <a href="https://www.linkedin.com/in/pedro-2001/" className='link-merchan' target='_blank' rel="noreferrer">Desenvolvido PedroDev <PiDevicesFill size={30} /></a>
              </Col>
            </Row>
          </Container>


        </div>
      </div>
    </footer>
  );
}

export default Footer;