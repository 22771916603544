export const textoSobreAlessandra = `
<div>
  <p><strong>Advogada</strong> regularmente inscrita na seccional da OAB MG/BH, formada pela Pontifícia Universidade Católica de Minas Gerais (2020).</p>

  <p><strong>Pós-Graduação:</strong></p>
  <p>- Direito do Trabalho e Previdenciário - IEC PUC MINAS (2021)</p>
  <p>- Direito Médico e Bioética - IEC PUC MINAS (2023)</p>
  <p>- Regularização Fundiária (REURB) - IEC PUC MINAS (em andamento, 2024)</p>

  <p><strong>Experiência Profissional:</strong></p>
  <p>- Sócia Proprietária da Drumond e Crivellari Sociedade de Advogados</p>
  <p>- CEO e Diretora de Operações da Escritura Brasil LTDA</p>
</div>

`;
