import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import './SobreNos.css'
import FT1 from '../../images/FT4.jpg'
import FT2 from '../../images/FT5.jpg'

import { useInView } from "react-intersection-observer";
import animacoes from '../../helpers/Anins';
import Slider from "react-slick";
import CardApresentacao from './CardApresentacao';

import {textoSobreAlessandra} from '../../texts/SobreAle';
import {textoSobreAlessandraResumo} from '../../texts/SobreAleResumo';
import { textoSobreThiago } from '../../texts/SobreThiago';
import { textoSobreThiagoResumo } from '../../texts/SobreThiagoResumo';

import 'animate.css'
import ModalAboutMe from '../ModalAboutMe/ModalAboutMe';
// import { Container } from './styles';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "", right: '100px' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "", left: '100px' }}
      onClick={onClick}
    />
  );
}

function SobreNos() {
    const { ref, inView, entry } = useInView({
        /* Optional options */
        triggerOnce: true
      });
    const [modal, setModal] = useState(false);
    const [modalText, setModalText] = useState('');
    const [modalTitle, setModalTitle] = useState('');

      const settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
          //576 retirar setas
          {
            breakpoint: 576,
            settings: {
              arrows: false
            }
          }
        ],
        waitForAnimate: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />

      };

      function handleOpenModal(adv){
        if(adv === 'Alessandra') {
          setModalText(textoSobreAlessandra);
          setModalTitle('Alessandra Drumond');
        }else if(adv === 'Thiago'){
          setModalText(textoSobreThiago);
          setModalTitle('Thiago Crivellari');
        }

        setModal(true);

      }

    return (
    <>
    <a  id='sobre'></a>
    <ModalAboutMe open={modal} setOpen={setModal} title={modalTitle} text={modalText} />

        <Container>
            <Row  className='areaAtuacao-containerNames'>
                <Col md={6} className='box-adv'>
                    <h1 className='areaAtuacao-adv-t1'>Alessandra Drumond</h1>
                    <h6 className='areaAtuacao-adv-t2'>Advogada OAB/MG 218.009</h6>
                    <span className='link-about-modal' onClick={() => handleOpenModal('Alessandra')}>Saiba mais sobre mim</span>
                </Col>
                <Col md={6} className='box-adv'>
                    <h1 className='areaAtuacao-adv-t1'>Thiago Crivellari</h1>
                    <h6 className='areaAtuacao-adv-t2'>Advogado OAB/MG 138.103</h6>
                    <span className='link-about-modal' onClick={() => handleOpenModal('Thiago')}>Saiba mais sobre mim</span>
                </Col>
            </Row>
        </Container>


      <Container fluid className='container-slider mb-3'>
    <Slider {...settings}>
        <div>
           <CardApresentacao foto={FT1} title={"Alessandra Drumond"} text={textoSobreAlessandraResumo} />
        </div>
        <div>
          <CardApresentacao foto={FT2} title={"Thiago Crivellari"} text={textoSobreThiagoResumo} />
        </div>
      </Slider>
      </Container>
    </>
  );
}

export default SobreNos;