export const textoSobreThiago = `
<div>
  <p><strong>Sócio Fundador</strong> da Drumond e Crivellari Sociedade de Advogados. <strong>Diretor Jurídico</strong> da empresa Escritura Brasil.</p>

  <p><strong>Formação Acadêmica:</strong></p>
  <p>- Doutorando em Direito na Pontifícia Universidade Católica de Minas Gerais (PUC Minas), na linha de pesquisa Teoria e Filosofia do Direito, orientado pelo Prof. Doutor Alexandre Travessoni Gomes Trivisonno (PUC MG e UFMG). Bolsista CAPES.</p>
  <p>- Mestre em Direito pela Universidade Federal de Ouro Preto, na linha de pesquisa Diversidade Cultural, Novos Sujeitos e Novos Sistemas de Justiça (2020), com dissertação publicada como o livro "<em>Decisionismo judicial brasileiro: o escandaloso caso Lula-Triplex</em>" pela Editora Dialética.</p>
  <p>- Bacharel em Direito pela Universidade Federal de Minas Gerais (UFMG) (2012) e em Filosofia pela Universidade Federal de Ouro Preto (UFOP) (2019).</p>

  <p><strong>Experiência Profissional:</strong></p>
  <p>- Consultor jurídico da Câmara de Vereadores de Piraúba e Procurador do Município no mesmo local.</p>
  <p>- Atuação em Direito Tributário contencioso em Salvador, Rio de Janeiro, Belo Horizonte e Brasília (2012-2015), com experiência em tribunais como TJDFT, STJ, STF e TRF1.</p>
  <p>- Desde 2019, advogado atuante no Tribunal do Júri.</p>

  <p><strong>Atividades Adicionais:</strong></p>
  <p>- Membro da Comissão de Direito Criminal e Assuntos Prisionais da OAB/JF e colaborador da Pastoral Carcerária em Juiz de Fora.</p>
  <p>- Atual membro da Comissão de Exame de Ordem da OAB MG.</p>
  <p>- Experiência docente nas disciplinas Teoria da Norma e Ordenamento Jurídico, Teoria da Justiça, e Teoria do Crime em unidades da PUC Minas.</p>

  <p><strong>Outras Informações:</strong></p>
  <p>- Diploma de High School concluído em Boston, Massachusetts.</p>
  <p>- Interesses de pesquisa incluem Crítica do Direito e Subjetividade Jurídica, Filosofia Contemporânea, Educação advocatícia e Exame de Ordem.</p>
  <p><strong>ORCID:</strong> 0000-0002-6676-7795</p>
</div>
...
`;
