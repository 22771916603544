import React from 'react';
import './ModalAboutMeStyle.css';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';


function ModalAboutMe({open, setOpen, title, text}) {
  return (
    <>
    <Modal size='xl' isOpen={open}  >
        <ModalHeader toggle={() => setOpen(!open)}>
            <h2>{title}</h2>
        </ModalHeader>
        <ModalBody>
        <div dangerouslySetInnerHTML={{ __html: text }} />
        </ModalBody>
    </Modal>
    </>
  );
}

export default ModalAboutMe;