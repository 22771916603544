import React,{useState} from 'react';

import './FaleConoscoStyle.css';
import { Button, Col, Container, Input, Label, Row } from 'reactstrap';

function FaleConosco() {
const [nome, setNome] = useState('');
const [email, setEmail] = useState('');

const phoneNumberEscritorio = '553198080310'; // Exemplo: 55 é o código do Brasil



function handleClick() {

    if (!nome) {
        alert('Por favor, insira um nome válido.');
        return;
    }

    if (!email) {
        alert('Por favor, insira um e-mail válido.');
        return;

    }

    const message = `
    Olá, gostaria de falar com um advogado.
    Meu nome é ${nome} e meu e-mail é ${email}.
    `;
    const encodedMessage = encodeURIComponent(message);
    const whatsappLink = `https://wa.me/${phoneNumberEscritorio}?text=${encodedMessage}`;

    window.open(whatsappLink, '_blank');
}
  return (
    <>
      <Container className='container-faleConosco animate__animated animate__backInUp'>
         <Row>
            <Col md={4} className='faleConosco-col1'>
                <h1 className='title-faleConosco'>Fale conosco</h1>
                <p className='sub-title-faleConosco'>Envie sua mensagem<br></br> e retornamos o mais rápido possível</p>
            </Col>
            <Col className='faleConosco-col2'>
                <Label className='lead'>Nome</Label>
                <Input  type='text' onChange={(e) => setNome(e.target.value)} placeholder='Digite seu Nome' />
            </Col>
            <Col  className='faleConosco-col3' >
                <Label className='lead'>Email</Label>
                <Input type='email' onChange={(e) => setEmail(e.target.value)} placeholder='Digite seu email' />
            </Col>
            <Col  className='faleConosco-col4'>
                <Button color='danger' onClick={handleClick} className='btn-cta btn-enviar'>Enviar</Button>
            </Col>

         </Row>
      </Container>
    </>
  );
}

export default FaleConosco;