import React from "react";
import { Col, Input, Label, Row } from "reactstrap";
import { Container } from "reactstrap";
import './SubBanner.css';
import { BiCompass } from "react-icons/bi";

export default function SubBanner() {
    return (
        <>
        <a id='local'></a>
        <Container  className='sub-banner text-center '>
            <h6 className="text-sub">Precisando de assessoria Jurídica</h6>
            <h1 className="text-title">Venha falar conosco</h1>
        </Container>
        <Container className="box-contato" fluid  >
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d580.5366039483687!2d-43.02589087338105!3d-21.2747244075417!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa2fb49c1ee1a1f%3A0x518723c2e7c6e53e!2sMG-353%2C%20243%2C%20Pira%C3%BAba%20-%20MG%2C%2036170-000!5e0!3m2!1sen!2sbr!4v1718500323880!5m2!1sen!2sbr" width="100%" height="500"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </Container>
        </>
    )
}
