import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import A1 from '../../images/A1.jpg'
import A2 from '../../images/A2.webp'
import A3 from '../../images/A3.jpg'
import Fundo from '../../images/Fundo.png'
import './AreasAtuacao.css';
import { textoSobreThiago } from '../../texts/SobreThiago';
import { textoSobreAlessandra } from '../../texts/SobreAle';
import ModalAboutMe from '../ModalAboutMe/ModalAboutMe';

function AreasAtuacao() {
  const [modal, setModal] = useState(false);
  const [modalText, setModalText] = useState('');
  const [modalTitle, setModalTitle] = useState('');



  return (
    <>
      <a id='servicos'></a>
      <Container fluid style={{backgroundImage: `url(${Fundo})`}} className='areaAtucao-container'>
        <Row className='areaAtuacao-containerAreas'>
          <Col style={{ textAlign: "center" }} md={12}>
            <h1 className='title-red' style={{ fontWeight: "800" }}>Áreas de atuação</h1>
          </Col>
        </Row>

        <div className='container-listas'>
          <ul>
            <li>Direito Penal</li>
            <li>Direito Civil</li>
            <li>Direito Previdenciário</li>


          </ul>

          <ul>
            <li>Direito de Família e Sucessões</li>
            <li>Direito Eleitoral</li>
            <li>Direito Médico e da Saúde</li>


          </ul>

          <ul>
          <li>Direito Trabalhista</li>
          <li>Direito Consumerista</li>
          <li>Direito Imobiliário</li>

          </ul>
        </div>
      </Container>
    </>
  );
}

export default AreasAtuacao;