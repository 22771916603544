export const textoSobreAlessandraResumo = `
<p><strong>Sócia Proprietária</strong> da Drumond e Crivellari Sociedade de Advogados. <strong>CEO e Diretora de Operações</strong> da Escritura Brasil LTDA.</p>

<p><strong>Formação Acadêmica:</strong></p>
<ul>
  <li>Advogada inscrita na OAB/MG, formada pela Pontifícia Universidade Católica de Minas Gerais (PUC Minas), 2020.</li>
</ul>
<ul>
  <li>Direito do Trabalho e Previdenciário - IEC PUC Minas (2021)</li>
  <li>Direito Médico e Bioética - IEC PUC Minas (2023)</li>
  <li>Regularização Fundiária (REURB) - IEC PUC Minas (em andamento, 2024)</li>
</ul>


`;
