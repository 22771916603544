import React from 'react';
import './BannerStyle.css';
import { Container, Row, Col, List, Button } from 'reactstrap';
import { FaPhoneAlt,FaLinkedin,FaInstagram,FaFacebook, FaWhatsapp } from 'react-icons/fa';
import LogoNoBg from '../../images/Logo_NoBg.png';
import BannerImage from '../../images/Banner1.jpg';


// import { Container } from './styles';

function Banner() {
    const imageUrl = 'url(https://img.freepik.com/fotos-gratis/ambiente-fotorrealista-do-advogado_23-2151151939.jpg?t=st=1717829044~exp=1717832644~hmac=5f5749cb9cbd6910f0e9834629d234fb8992699cfe9ceb294b87c996886a580f&w=1380)'

    const redirectInstagran = () => {
        const link = 'https://www.instagram.com/drumondecrivellari?igsh=M2lmenlhdjQ4djhh'
        window.open(link, '_blank')
    }

    const sendMessageWhats = () => {
        const phoneNumberEscritorio = '553198080310';
        const message = "Olá, gostaria de falar com um advogado.";
        const whatsappLink = `https://wa.me/${phoneNumberEscritorio}?text=${message}`;
        window.open(whatsappLink, '_blank');
    }

    return (
    <>
    <div style={{ backgroundImage: `url(${BannerImage})`,backgroundSize:'cover',backgroundPosition: 'center'}} className='banner '>
        <div  className='line-chesse'>
       <Container className='animate__animated animate__backInDown' >
        <Row className=' linha-icons' >
            <Col className='telefone ' >
                <FaPhoneAlt size={15} className='mr-1' /> <span style={{padding:"5px"}}>(31) 9 98080310</span>
            </Col>
            <Col className='list-icons'>
                <FaInstagram style={{cursor:"pointer"}} size={24} className='m-1' onClick={redirectInstagran} />
                <FaWhatsapp style={{cursor:"pointer"}} size={24} className='m-1' onClick={sendMessageWhats} />

            </Col>
        </Row>

       </Container>
        </div>
        <Container className='mt-2 animate__animated animate__backInDown'>
            <Row className='nav-main'>
                <Col className='brand-box'>
                    <img src={LogoNoBg} width={'100px'} height={'120px'} alt='Logo da empresa' />
                    <p className='brand'>
                        Drumond&Crivellari
                       <span className='sub-brand'> Advocacia e Consultoria Jurídica</span>
                    </p>

                </Col>

                <Col>
                    <List  className='menu'>
                        <li className='menu-item'><a href='#home'>Home</a></li>
                        <li className='menu-item'><a href='#sobre'>Sobre</a></li>
                        <li className='menu-item'><a href='#servicos'>Serviços</a></li>
                        <li className='menu-item'><a href='#servicos'>Equipe</a></li>
                        <li className='menu-item'><a href='#local'>Endereço</a></li>
                    </List>
                </Col>

                <Col className='button-box'>
                    <Button color='danger' onClick={sendMessageWhats} className='btn-cta btn-banner'>Fale Conosco <FaWhatsapp size={24} /></Button>
                </Col>

            </Row>

        </Container>
        <Container className='banner-text'>
            <Row>
                <Col md='12' className='mt-4 animate__animated animate__backInLeft'>
                    <h1  className='title-main'>Descomplicando Problemas</h1>
                    <h1  className='title-main'>Entregando Soluções</h1>
                    {/*<a href="mailto:email@example.com?subject=Assunto%20do%20Email&body=Olá%20este%20é%20o%20corpo%20do%20email.">Envie um email</a>*/}


                </Col>
            </Row>
        </Container>

    </div>

    </>
  );
}

export default Banner;