import logo from './logo.svg';
import './App.css';
import Banner from './Components/Banner/Banner';
import FaleConosco from './Components/FaleConosco/FaleConosco';
import SobreNos from './Components/SobreNos/SobreNos';
import AreasAtuacao from './Components/AreasAtuacao/AreasAtuacao';
import SubBanner from './Components/SubBanner/SubBanner';
import Footer from './Components/Footer/Footer';
import 'animate.css';

function App() {
  return (
    <>
      <Banner />
      <FaleConosco />
      <SobreNos />
      <br></br>
      <AreasAtuacao />
      <SubBanner />
      <Footer />
    </>
  );
}

export default App;
