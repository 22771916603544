import React from 'react';
import animacoes from '../../helpers/Anins';
import { Card, Col, Container, Row } from 'reactstrap';



// import { Container } from './styles';

function CardApresentacao({title, text, foto}) {
return (




        <div className='container-card'>
            <div className={`canvas-images`} xs="12" md="4">
                <img className='image1' src={foto} alt="Foto do advogado" />
            </div>
            <div className='text-sobre-nos' xs="12" md="8">
                <Container>

                    <h1 className='title-sobrenos'>{title}</h1>
                    <p className='text-sobre'>
                        <div className='caixa-html' dangerouslySetInnerHTML={{ __html: text }} />

                    </p>

                </Container>
            </div>
        </div>



);
}

export default CardApresentacao;